import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'src/routes/hooks';
import { setSite } from 'src/store/store';
import { useGetUserDetailsMutation } from 'src/store/auth/auth-api';
import { AuthContext } from './auth-context';
import { setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';
const STORAGE_USER_KEY = 'user';

export function AuthProvider({ children }) {
  const dispatchRedux = useDispatch();

  const [state, dispatch] = useReducer(reducer, initialState);

  const [getUser] = useGetUserDetailsMutation();

  const searchParams = useSearchParams();

  const initialize = useCallback(async () => {
    try {
      const accessTokenFromParams = searchParams.get('token');
      const userFromParams = searchParams.get('user');

      if (accessTokenFromParams && userFromParams) {
        let userDetails;
        let token;
        try {
          const response = await getUser(accessTokenFromParams).unwrap();
          console.log(response);

          // Create a new object with spread operator to ensure it's extensible
          userDetails = {
            ...response,
            site: JSON.parse(userFromParams).site,
          };

          token = response?.token ?? '';
          console.log(userDetails);

        } catch (e) {
          console.log(e);
          userDetails = null; // Use null instead of an empty string
          token = '';
        }

        // Store as a string in localStorage
        localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(userDetails));
        localStorage.setItem(STORAGE_KEY, token);
      }

      const accessToken = localStorage.getItem(STORAGE_KEY);
      const user = JSON.parse(localStorage.getItem(STORAGE_USER_KEY) || 'null');

      if (user && user.site) {
        dispatchRedux(setSite(user.site)); // Dispatch the setSite action
      }

      dispatch({
        type: 'INITIAL',
        payload: {
          user: accessToken && user ? user : null,
        },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);


  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);


  // UPDATE USER PERMISSION
  const updateUserPermission = useCallback(async (permissions) => {
    const user = JSON.parse(localStorage.getItem(STORAGE_USER_KEY));
    user.permissions = permissions;
    localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(user));
    dispatch({
      type: 'INITIAL',
      payload: {
        user,
      },
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      logout,
      updateUserPermission,
    }),
    [logout, state.user, status, updateUserPermission]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
