import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQueryFn';

export const documentApi = createApi({
  reducerPath: 'documentApi',
  baseQuery,
  endpoints: (builder) => ({
    getDocument: builder.query({
      query: ({ site_id, page, limit, search }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

        const apiUrl = `site/v1/${site_id}/documents?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      transformResponse: (response) => response,
      providesTags: ['document'],
    }),
    createDocument: builder.mutation({
      query: ({ body, site_id }) => ({
        url: `site/v1/${site_id}/documents/new`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['document'],
    }),
    updateDocument: builder.mutation({
      query: ({ body, site_id }) => ({
        url: `site/v1/${site_id}/documents/edit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['document'],
    }),
    getDocumentByID: builder.query({
      query: ({ id, site_id }) => ({
        url: `site/v1/${site_id}/documents/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => response,
      providesTags: ['document'],
    }),
    deleteDocument: builder.mutation({
      query: ({ body, site_id }) => ({
        url: `site/v1/${site_id}/documents/delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['document'],
    }),
  }),
});

export const {
  useGetDocumentQuery,
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
  useGetDocumentByIDQuery,
  useDeleteDocumentMutation,
} = documentApi;
