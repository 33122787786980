// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// routes
import { useEffect } from 'react';
import { onMessage } from 'firebase/messaging';
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';
import { LocalizationProvider } from './locales';
import { SnackbarProvider } from './components/snackbar';
import { generatePlayerId, messaging } from './firebase/firebase';
import { useRegisterPlayerIdMutation } from './store/notification/notification-api';

// ----------------------------------------------------------------------

export default function App() {
  const [registerPlayerId] = useRegisterPlayerIdMutation();


  useScrollToTop();

  useEffect(() => {
    const handleGeneratePlayerId = async () => {
      try {
        const value = await generatePlayerId();
        const storedPlayerId = localStorage.getItem('playerId');
        if (localStorage.getItem('accessToken')) {
          if (value && value !== storedPlayerId) {
            localStorage.setItem('playerId', value);
            await registerPlayerId({ "firebase_player_id": value }).unwrap();
          }
        }

      } catch (error) {
        console.error('Error generating or registering player ID:', error);
      }
    };

    handleGeneratePlayerId();

    const unsubscribe = onMessage(messaging, (payload) => {
      try {
        console.log('Message received. ', payload);
      } catch (error) {
        console.error('Error handling incoming message:', error);
      }
    });

    return () => unsubscribe();

  }, [registerPlayerId]);


  return (
    <AuthProvider>
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'cyan', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: true,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SettingsDrawer />
              <SnackbarProvider>
                <ProgressBar />
                <AuthConsumer>
                  <Router />
                </AuthConsumer>
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}
