import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQueryFn';

export const drawingApi = createApi({
  reducerPath: 'drawingApi',
  baseQuery,
  endpoints: (builder) => ({
    getDrawing: builder.query({
      query: ({ site_id, page, limit, search }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

        const apiUrl = `site/v1/${site_id}/drawings?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      transformResponse: (response) => response,
      providesTags: ['drawing'],
    }),
    createDrawing: builder.mutation({
      query: ({ body, site_id }) => ({
        url: `site/v1/${site_id}/drawings/new`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['drawing'],
    }),
    updateDrawing: builder.mutation({
      query: ({ body, site_id }) => ({
        url: `site/v1/${site_id}/drawings/edit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['drawing'],
    }),
    getDrawingByID: builder.query({
      query: ({ id, site_id }) => ({
        url: `site/v1/${site_id}/drawings/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => response,
      providesTags: ['drawing'],
    }),
    deleteDrawing: builder.mutation({
      query: ({body, site_id}) => ({
        url: `site/v1/${site_id}/drawings/delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['drawing'],
    }),
  }),
});

export const {
  useGetDrawingQuery,
  useCreateDrawingMutation,
  useUpdateDrawingMutation,
  useGetDrawingByIDQuery,
  useDeleteDrawingMutation,
} = drawingApi;
