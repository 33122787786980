import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
import { hasPermission } from 'src/utils/permissions';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name) => <Iconify icon={name} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  file: icon('ic:file'),
  order: icon('fa6-solid:cart-arrow-down'),
  task: icon('streamline:task-list-solid'),
  invoice: icon('ic:invoice'),
  analytics: icon('clarity:analytics-solid'),
  drawing: icon('hugeicons:drawing-compass'),
  assignments: icon('fa6-solid:clock'),
  document: icon('oui:documents'),
  clientPayment: icon('material-symbols:payments-outline'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { user } = useAuthContext();

  const data = useMemo(
    () => [
      {
        items: [
          {
            title: 'Overview',
            path: paths.dashboard.root,
            icon: ICONS.analytics,
          },
          hasPermission(user, 'Tasks', 'Read') && {
            title: 'Tasks',
            path: paths.dashboard.tasks.root,
            icon: ICONS.task,
          },
          hasPermission(user, 'Laborers Assignments', 'Read') && {
            title: 'Assignments',
            path: paths.dashboard.assignments.root,
            icon: ICONS.assignments,
          },
          hasPermission(user, 'Shops Purchase Orders', 'Read') && {
            title: 'Purchase Order',
            path: paths.dashboard.purchaseOrder.root,
            icon: ICONS.order,
          },
          hasPermission(user, 'Drawings', 'Read') && {
            title: 'Drawings',
            path: paths.dashboard.drawings.root,
            icon: ICONS.drawing,
          },
          hasPermission(user, 'Documents', 'Read') && {
            title: 'Documents',
            path: paths.dashboard.documents.root,
            icon: ICONS.document,
          },
          // hasPermission(user, 'Client Milestone Payment', 'Read') && {
          //   title: 'Client Payments',
          //   path: paths.dashboard.clientPayments.root,
          //   icon: ICONS.clientPayment,
          // },
        ].filter(Boolean),
      },
    ],
    [user]
  );

  return data;
}
