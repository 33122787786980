import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
    }),
    endpoints: (builder) => ({
        getUserDetails: builder.mutation({
            query: (token) => ({
                url: `auth/v1/users/login-by-token`,
                method: "POST",
                headers: {
                    'authorization': `Bearer ${token}`
                }
            }),
            transformResponse: (response) => response,
        }),
    }),
});

export const { useGetUserDetailsMutation } = authApi;
