import { m } from 'framer-motion';
import { useState, useEffect } from 'react';
// @mui
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
//
import { useGetNotificationCountQuery, useGetNotificationQuery, useReadNotificationMutation } from 'src/store/notification/notification-api';
import NotificationItem from './notification-item';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const drawer = useBoolean();

  const smUp = useResponsive('up', 'sm');


  const [notifications, setNotifications] = useState([]);


  const { data } = useGetNotificationCountQuery();

  const { isFetching, isLoading, data: notificationData } = useGetNotificationQuery();

  const [readNotification] = useReadNotificationMutation();


  useEffect(() => {
    if (notificationData) {
      setNotifications(notificationData)
    }
  }, [notificationData]);


  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );


  const handleReadNotification = async (id) => {
    try {
      await readNotification(id).unwrap();
    } catch (e) {
      //
    }
  };

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notifications.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} readNotification={() => handleReadNotification(notification.id)} />
        ))}
      </List>
    </Scrollbar>
  );


  let notificationContent;

  if (isFetching || isLoading) {
    notificationContent = <span> Loading</span>
  }
  else if (notificationData) {
    notificationContent = (
      <>
        {renderHead}

        <Divider />

        {renderList}
      </>
    )
  }

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={data ?? 0} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {notificationContent}
      </Drawer>
    </>
  );
}
