import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../utils/baseQueryFn';

export const purcahseOrderApi = createApi({
  reducerPath: 'purcahseOrderApi',
  baseQuery,
  endpoints: (builder) => ({
    getPurcahseOrder: builder.query({
      query: ({ page, limit, search, status, shops, site_id }) => {
        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (search) {
          queryParams.append('search', search);
        }

        if (status) {
          queryParams.append('status', status);
        }

        if (shops.length > 0) {
          queryParams.append('shop_ids', JSON.stringify(shops));
        }

        const apiUrl = `site/v1/${site_id}/purchase-orders/all?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: "GET",
        };
      },
      transformResponse: (response) => response,
      providesTags: ["purchaseorder"],
    }),
    getAllPurcahseOrder: builder.mutation({
      query: ({ page, limit, status,site_id, shops, startDate, endDate }) => {

        const queryParams = new URLSearchParams({
          page,
          limit,
        });

        if (status) {
          queryParams.append('status', status);
        }

        if (shops.length > 0) {
            queryParams.append('shop_ids', JSON.stringify(shops));
        }

        if (startDate) {
            queryParams.append('start_date', startDate);
        }

        if (endDate) {
            queryParams.append('end_date', endDate);
        }
        const apiUrl = `site/v1/${site_id}/purchase-orders/all?${queryParams.toString()}`;

        return {
          url: apiUrl,
          method: 'GET',
        };
      },
      transformResponse: (response) => response,
      providesTags: ['purchaseorder'],
    }),
    getAllPurchaseOrders: builder.mutation({
        query: ({page, limit, status,site_id, shops, startDate, endDate }) => {
  
          const queryParams = new URLSearchParams({
            page,
            limit,
          });
  
          if (status) {
            queryParams.append('status', status);
          }
  
          if (shops.length > 0) {
              queryParams.append('shop_ids', JSON.stringify(shops));
          }
  
          if (startDate) {
              queryParams.append('start_date', startDate);
          }
  
          if (endDate) {
              queryParams.append('end_date', endDate);
          }
          const apiUrl = `site/v1/${site_id}/purchase-orders/all?${queryParams.toString()}`;
    
          return {
            url: apiUrl,
            method: 'GET',
          };
        },
        transformResponse: (response) => response,
        providesTags: ['purchaseorder'],
      }),
    createPurcahseOrder: builder.mutation({
      query: ({body,site_id}) => ({
        url: `site/v1/${site_id}/purchase-orders/new`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['purchaseorder'],
    }),
    updatePurcahseOrder: builder.mutation({
      query: ({body,site_id}) => ({
        url: `site/v1/${site_id}/purchase-orders/edit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['purchaseorder'],
    }),
    getPurcahseOrderByID: builder.query({
      query: ({site_id,id}) => ({
        url: `site/v1/${site_id}/purchase-orders/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => response,
      providesTags: ['purchaseorder'],
    }),
    deletePurcahseOrder: builder.mutation({
      query: ({body,site_id}) => ({
        url: `site/v1/${site_id}/purchase-orders/delete`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['purchaseorder'],
    }),
    confirmPurcahseOrder: builder.mutation({
      query: ({body,site_id}) => ({
        url: `site/v1/${site_id}/purchase-orders/mark-as-confirmed`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['purchaseorder'],
    }),
  }),
});

export const {
  useGetPurcahseOrderQuery,
  useCreatePurcahseOrderMutation,
  useUpdatePurcahseOrderMutation,
  useGetPurcahseOrderByIDQuery, useGetAllPurcahseOrderMutation,
  useDeletePurcahseOrderMutation,useConfirmPurcahseOrderMutation
} = purcahseOrderApi;
