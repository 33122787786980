import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


export const subTaskApi = createApi({
    reducerPath: "subTaskApi",
    baseQuery,
    endpoints: (builder) => ({
        getSubTask: builder.query({
            query: ({ page, limit, startDate, endDate, status, task_id, site_id }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                const apiUrl = `site/v1/${site_id}/tasks/${task_id}/sub-tasks?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["tasks"],
        }),
        getAllSubTask: builder.mutation({
            query: ({ page, limit, startDate, endDate, status, task_id, site_id }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                if (status) {
                    queryParams.append('status', status);
                }

                const apiUrl = `site/v1/${site_id}/tasks/${task_id}/sub-tasks?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["tasks"],
        }),
        updateSubTaskProgress: builder.mutation({
            query: ({body, site_id, task_id}) => ({
                url: `site/v1/${site_id}/tasks/${task_id}/sub-tasks/edit-progress`,
                method: "POST",
                body
            }),
            invalidatesTags: ["tasks"],
        }),
        updateInstallmentPayment: builder.mutation({
            query: ({body, site_id}) => ({
                url: `site/v1/${site_id}/subcontract-installments/mark-as-paid`,
                method: "POST",
                body
            }),
            invalidatesTags: ["tasks"],
        }),
    }),
});

export const { useGetSubTaskQuery, useUpdateSubTaskProgressMutation,useUpdateInstallmentPaymentMutation,useGetAllSubTaskMutation } = subTaskApi;
