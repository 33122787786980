import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


export const dataApi = createApi({
    reducerPath: "dataApi",
    baseQuery,
    endpoints: (builder) => ({
        uploadImage: builder.mutation({
            query: ({ body, model }) => ({
                url: `main/v1/document/upload?model_name=${model}`,
                method: "POST",
                body
            }),
        }),
        getUnitsData: builder.query({
            query: (site_id) => ({
                url: `site/v1/${site_id}/data/units`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        getTasksData: builder.query({
            query: (site_id) => ({
                url: `site/v1/${site_id}/tasks/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        getAssignmentData: builder.query({
            query: (site_id) => ({
                url: `site/v1/${site_id}/assignments/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        getWorkLogData: builder.query({
            query: ({ site_id, task_id }) => ({
                url: `site/v1/${site_id}/tasks/${task_id}/work-logs/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
        getPurchaseData: builder.query({
            query: (site_id) => ({
                url: `site/v1/${site_id}/purchase-orders/data`,
                method: "GET",
            }),
            transformResponse: (response) => response,
            providesTags: ['purchaseorder'],
        }),
        checkPermission: builder.mutation({
            query: () => ({
                url: `auth/v1/users/sync`,
                method: "GET",
            }),
            transformResponse: (response) => response,
        }),
    }),
});

export const { useUploadImageMutation, useGetUnitsDataQuery, useGetTasksDataQuery, useGetPurchaseDataQuery, useGetAssignmentDataQuery, useGetWorkLogDataQuery,useCheckPermissionMutation } = dataApi;
