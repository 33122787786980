import { configureStore, createSlice } from '@reduxjs/toolkit';
import { dataApi } from './data/data-api';
import { drawingApi } from './drawings/drawing-api';
import { taskApi } from './task/task-api';
import { subTaskApi } from './task/sub-task-api';
import { documentApi } from './documents/document-api';
import { assignmentsApi } from './assignments/assignments-api';
import { workLogApi } from './task/work-log-api';
import { purcahseOrderApi } from './purchase-order/purchase-order-api';
import { authApi } from './auth/auth-api';
import { notificationApi } from './notification/notification-api';

const homeSlice = createSlice({
  name: 'home',
  initialState: {
    cardView: JSON.parse(localStorage.getItem('cardView')) ?? false,
  },
  reducers: {
    setCardView: (state, action) => {
      state.cardView = action.payload;
      localStorage.setItem('cardView', JSON.stringify(action.payload));
    },
  },
});

const tabsSlice = createSlice({
  name: 'tabs',
  initialState: { currentTab: 'subtasks' },
  reducers: {
    setTab: (state, action) => {
      state.currentTab = action.payload;
    },
  },
});

const siteSlice = createSlice({
  name: 'site',
  initialState: {
    site: null,
  },
  reducers: {
    setSite: (state, action) => {
      state.site = action.payload;
    },
    clearSite: (state) => {
      state.site = null;
    },
  },
});

const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    [dataApi.reducerPath]: dataApi.reducer,
    [drawingApi.reducerPath]: drawingApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    tabs: tabsSlice.reducer,
    site: siteSlice.reducer,
    [taskApi.reducerPath]: taskApi.reducer,
    [subTaskApi.reducerPath]: subTaskApi.reducer,
    [purcahseOrderApi.reducerPath]: purcahseOrderApi.reducer,
    [assignmentsApi.reducerPath]: assignmentsApi.reducer,
    [workLogApi.reducerPath]: workLogApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(dataApi.middleware)
      .concat(drawingApi.middleware)
      .concat(taskApi.middleware)
      .concat(subTaskApi.middleware)
      .concat(documentApi.middleware)
      .concat(assignmentsApi.middleware)
      .concat(workLogApi.middleware)
      .concat(purcahseOrderApi.middleware)
      .concat(documentApi.middleware)
      .concat(authApi.middleware)
      .concat(notificationApi.middleware),
});

export const homeActions = homeSlice.actions;
export const { setTab } = tabsSlice.actions;
export const { setSite, clearSite } = siteSlice.actions;

export default store;
