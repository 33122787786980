import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';


export const workLogApi = createApi({
    reducerPath: "workLogApi",
    baseQuery,
    endpoints: (builder) => ({
        getWorkLog: builder.query({
            query: ({ page, limit, startDate, endDate,laborers,teamLeads, task_id, site_id }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                if (laborers.length > 0) {
                    queryParams.append('laborers_ids', JSON.stringify(laborers));
                }

                if (teamLeads.length > 0) {
                    queryParams.append('team_ids', JSON.stringify(teamLeads));
                }

                const apiUrl = `site/v1/${site_id}/tasks/${task_id}/work-logs?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["tasks"],
        }),
        getAllWorkLog: builder.mutation({
            query: ({ page, limit, startDate, endDate,laborers,teamLeads, task_id, site_id }) => {

                const queryParams = new URLSearchParams({
                    page,
                    limit,
                });

                if (startDate) {
                    queryParams.append('start_date', startDate);
                }

                if (endDate) {
                    queryParams.append('end_date', endDate);
                }

                if (laborers.length > 0) {
                    queryParams.append('laborers_ids', JSON.stringify(laborers));
                }

                if (teamLeads.length > 0) {
                    queryParams.append('team_ids', JSON.stringify(teamLeads));
                }

                const apiUrl = `site/v1/${site_id}/tasks/${task_id}/work-logs?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["tasks"],
        }),
    }),
});

export const { useGetWorkLogQuery, useGetAllWorkLogMutation } = workLogApi;
